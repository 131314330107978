.tag-item {
  display: inline-flex;
  align-items: center;
  gap: $size-4;
  padding: $size-2 $size-10;
  border-radius: $size-2;
  color: $ink;
  border: $size-1 solid;

  &.chip {
    padding: $size-5 $size-16;
    border-radius: $size-20;
    border: none;
    font-weight: bold;

    &.color-green {
      background-color: $secondary-lighter;
    }

    &.color-blue {
      background-color: $primary-lighter;
    }

    &.color-purple {
      background-color: $tertiary-lighter;
    }

    &.color-navy {
      background-color: $info-light;
      // color: $white;
    }

    &.color-orange {
      background-color: $accent-warm-lighter;
    }
  }

  &.color-green {
    background-color: $secondary-lighter;
    border-color: $secondary-light;
  }

  &.color-blue {
    background-color: $primary-lighter;
    border-color: $primary-light;
  }

  &.color-purple {
    background-color: $tertiary-lighter;
    border-color: $tertiary-light;
  }

  &.color-navy {
    background-color: $info-lighter;
    border-color: $info-light;
  }

  &.color-orange {
    background-color: $accent-warm-lighter;
    border-color: $accent-warm-light;
  }
}
