$beta-banner-height: 2.4rem;
$filterbox-height: 2.5rem;
$header-height: 3.8rem;
$footer-height: 6.125rem;
$footer-height-mobile: 6.5rem;
$result-count-height: 5.3rem;
$comparison-height: 12rem;

$below-banners-height: 260px;
$below-banners-height-mobile: 165px;
