.accordion {
  margin-bottom: $size-16;

  button {
    font-weight: bold;
    color: $ink;
    background-color: $base-lighter;
    width: 100%;
    padding: 1.2rem 4rem 1.2rem $size-16;
    position: relative;
    display: flex;
    align-items: center;
    gap: $size-16;

    &:before,
    &:after {
      content: "";
      position: absolute;
      height: $size-2;
      border-radius: $size-2;
      width: 15px;
      background: $ink;
      top: 50%;
      right: $size-24;
      z-index: 1;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }

    &:after {
      transform: rotate(90deg);
    }

    &:focus {
      background: $light-purple;
    }
  }

  &.open {
    button {
      &:after {
        transform: rotate(0deg);
      }
    }
  }

  .content {
    height: 0px;
    overflow: hidden;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    .inner {
      max-width: 35rem;
      padding: $size-16 1.5rem;
    }
  }
}
