.occupation-block {
  // margin: 0 0 $size-60 0;

  .error-message {
    margin-bottom: $size-48;
  }

  button {
    &.usa-button--unstyled {
      color: $primary;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .container {
    + .container {
      @media screen and (max-width: ($tablet - 1)) {
        padding: 0;
      }
    }
  }

  .heading-tag {
    font-size: $size-32;
    margin-bottom: $size-24;
  }

  .occupation-selector {
    max-width: 36rem;
    display: block;
    font-weight: bold;
    position: relative;
    margin-bottom: 2.2rem;

    &:after {
      content: "";
      position: absolute;
      right: $size-16;
      bottom: $size-16;
      width: 0;
      height: 0;
      border-left: 0.35rem solid transparent;
      border-right: 0.35rem solid transparent;
      border-top: 0.35rem solid $ink;
    }

    select {
      margin-top: 0.7rem;
      width: 100%;
      appearance: none;
      padding: 0.7rem 1.5rem 0.7rem 0.7rem;
    }
  }

  .occupation-box {
    @media screen and (min-width: ($tablet + 1)) {
      padding: $size-32 0 0;
    }

    .usa-tooltip__body {
      width: $size-160;
      white-space: normal;

      @media screen and (max-width: ($tablet - 1)) {
        width: $size-200;
      }
    }

    .path-title {
      font-size: $size-18;
      font-weight: bold;
    }

    .heading {
      display: flex;
      align-items: flex-start;
      gap: 0.5rem;
      padding-bottom: 0;
      border-bottom: none;

      @media screen and (max-width: ($mobile-lg - 1)) {
        flex-direction: column;
      }

      @media screen and (max-width: ($tablet - 1)) {
        padding: 0 $size-16;
      }

      h3 {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 0.7rem;
      }

      p {
        margin: 0;
        width: 100%;
      }

      + .single-path {
        padding: 0.5rem $size-16 0.5rem 0.2rem;

        @media screen and (max-width: ($tablet - 1)) {
          padding: 0.5rem $size-16;
        }
      }
    }

    .tag {
      @media screen and (min-width: ($mobile-lg)) {
        margin-left: $size-16;
      }
      @media screen and (max-width: ($mobile-lg - 1)) {
        margin: 0 0 0.5rem;
      }
    }
  }
  .occu-row {
    display: flex;
    width: 100%;
    gap: $size-32;
    align-items: flex-start;
    margin-bottom: $size-48;

    @media screen and (max-width: ($tablet - 1)) {
      flex-direction: column;
    }

    @media screen and (min-width: ($tablet)) {
      max-width: 1294px;
      margin: 0 auto $size-48;
    }

    > div {
      width: 100%;

      @media screen and (min-width: ($tablet)) {
        max-width: $size-520;
      }

      &:first-of-type {
        @media screen and (min-width: ($tablet-xl)) {
          max-width: calc(100% - $size-520);
        }
      }

      @media screen and (min-width: ($tablet)) {
        width: 100%;
      }
    }

    &.info {
      @media screen and (max-width: ($tablet-xl - 1)) {
        flex-direction: column-reverse;
      }

      > div {
        @media screen and (max-width: ($tablet-xl - 1)) {
          width: 100%;
        }
      }

      .box {
        @media screen and (min-width: ($tablet-xl)) {
          width: calc(100% - $size-520);
        }
      }
    }
  }

  .meta {
    display: flex;
    gap: $size-16;
    width: 100%;
    max-width: $size-520;

    @media screen and (max-width: ($mobile-lg - 1)) {
      gap: $size-16;
      flex-direction: column;
    }

    @media screen and (min-width: ($tablet-xl)) {
      width: 25rem;
    }

    @media screen and (max-width: ($tablet - 1)) {
      padding: 0 $size-16;
      margin-bottom: $size-16;
    }

    div {
      padding: 0.5rem $size-16;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      gap: $size-4;
      border-radius: $size-8;
      width: 100%;
      background-color: $tertiary-lighter;

      p {
        margin: 0;
      }

      .title,
      a {
        font-size: $size-16;
        display: flex;
        align-items: center;
        gap: $size-4;
        margin: 0;
      }

      a {
        font-size: $size-16;
      }
    }
  }

  .box {
    width: 100%;
    margin-bottom: $size-32;

    @media screen and (min-width: ($tablet)) {
      overflow: hidden;
      border-radius: $size-10;
    }

    @media screen and (max-width: ($tablet - 1)) {
      display: flex;
      flex-direction: column;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.related-jobs {
      .content {
        a {
          &.usa-button {
            background-color: $primary-lighter;
            border: $size-2 solid $primary-light;
          }
        }
      }
    }

    .content {
      padding: $size-16;

      @media screen and (min-width: ($tablet)) {
        border-left: $size-1 solid $base-lightest;
        border-right: $size-1 solid $base-lightest;
        border-bottom: $size-1 solid $base-lightest;
        border-bottom-left-radius: $size-10;
        border-bottom-right-radius: $size-10;
      }

      a {
        &.usa-button {
          text-align: left;
          background-color: $secondary-lighter;
          width: 100%;
          border: $size-2 solid $secondary;
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          gap: $size-16;
          color: $ink;
          border-radius: 10px;
          padding: 0.5rem;

          span {
            gap: $size-16;
            display: inline-flex;
            align-items: center;
          }

          + .usa-button {
            margin-top: 1.5rem;
            background-color: $info-lighter;
            border: $size-2 solid $info;
          }

          @media screen and (max-width: ($tablet-xl - 1)) {
            display: flex;
            width: 100%;
          }

          &:hover {
            @media screen and (min-width: ($tablet-xl + 1)) {
              background: white;
            }
          }

          svg {
            width: $size-28;
            height: auto;
          }

          + .usa-button {
            margin-top: $size-16;
          }
        }
      }

      &.related-training,
      &.related-occupations {
        a:not(.usa-button) {
          color: $primary;
          text-decoration: none;
          display: block;
        }
      }

      &.related-occupations {
        li {
          + li {
            margin-top: $size-16;
          }
        }
      }

      &.related-training {
        ul {
          display: flex;
          flex-direction: column;
          gap: $size-24;
          margin: $size-16 0 $size-32;
        }
        a:not(.usa-button) {
          border: 2px solid $base-lighter;
          border-radius: $size-8;
          padding: $size-16;
          transition-duration: $transition-speed;

          &:hover {
            @media screen and (min-width: ($tablet-md)) {
              box-shadow: $shadow-2;
            }
          }

          .title {
            line-height: 1.2;
            font-size: $size-16;
            margin-bottom: $size-16;
            font-weight: bold;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            svg {
              color: $ink;
            }
          }

          .span-grid {
            display: flex;
            flex-direction: column;
            margin: 0;
            gap: $size-16;

            span {
              color: $ink;
              display: flex;
              align-items: center;
              gap: 0.5rem;

              &.last-line {
                justify-content: space-between;
              }

              &.salary {
                color: $white;
                background-color: $base;
                padding: $size-4 $size-8 $size-2;
              }
            }
          }
        }
      }
    }
  }

  .heading-bar {
    background-color: $secondary-lighter;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    padding: 0.5rem 0.6rem;

    @media screen and (max-width: ($tablet - 1)) {
      position: sticky;
      top: 0;
    }
  }

  .heading-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: $size-32;

    @media screen and (max-width: ($desktop - 1)) {
      flex-direction: column;
    }

    .heading {
      max-width: $size-720;
      flex-wrap: wrap;

      @media screen and (min-width: ($desktop)) {
        width: calc(100% - $size-520);
        margin-bottom: $size-32;
      }
    }

    .meta {
      width: 100%;
      max-width: $size-520 + $size-16;
      margin-bottom: $size-32;
    }
  }

  &.career-detail {
    .map-block {
      background-color: $gray-cool-5;
      padding: $size-16;
      border-radius: $size-16;
    }

    .occupation-box {
      border: 0;
      border-radius: 0;
      background: white;
      padding: 0;

      .heading {
        margin-bottom: 1.5rem;
        max-width: 100%;
        gap: $size-32;
        flex-wrap: nowrap;

        @media screen and (max-width: ($desktop - 1)) {
          display: block;
        }

        h3 {
          margin: 0;
        }

        p {
          margin-top: 0.5rem;
          width: 100%;
          @media screen and (min-width: ($desktop)) {
            margin-right: 1.5rem;
          }
        }

        > div:not(.meta) {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          max-width: $size-720;

          @media screen and (min-width: ($desktop)) {
            width: calc(100% - $size-520);
          }
        }

        .meta {
          width: 100%;

          @media screen and (max-width: ($tablet-xl - 1)) {
            padding: 0;
            margin-top: $size-16;
            // opacity: 0.3;
          }

          div {
            p {
              margin: 0;
            }
          }
        }
      }
    }

    .box {
      line-height: 1.6;

      p {
        margin-bottom: $size-16;
      }
    }

    .breadcrumbs {
      color: $ink;
      font-size: 0.9rem;
      margin-top: $size-40;
      margin-bottom: $size-22;

      @media screen and (max-width: (
            $tablet - 1,
          )) {
        padding: 0 $size-16;
      }
    }
  }

  .full-map {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .extra {
      background-color: $gray-cool-5;
      z-index: 20;
      height: 0;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      width: 0;
      overflow: hidden;
    }

    &.open {
      .extra {
        visibility: visible;
        height: auto;
        width: auto;
        overflow: visible;
        opacity: 1;
        pointer-events: all;
      }
    }

    .map-title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .single-path {
      overflow: unset;
    }

    .close {
      position: absolute;
      top: $size-32;
      right: $size-32;
      cursor: pointer;
    }
  }

  .select-button {
    width: 100%;
    color: $ink;
    appearance: none;
    padding: 0.7rem $size-32 0.7rem 0.7rem;
    position: relative;
    font-weight: normal;
    border: $size-1 solid $base-lighter;
    border-radius: 5px;

    &:disabled {
      background-color: $base-lighter;
      color: $base;
      cursor: not-allowed;
    }

    &:after {
      content: "";
      position: absolute;
      right: $size-16;
      bottom: $size-16;
      width: 0;
      height: 0;
      border-left: 0.35rem solid transparent;
      border-right: 0.35rem solid transparent;
      border-top: 0.35rem solid $ink;
    }

    &.inactive {
      color: $base-light;
    }
  }

  .dropdown-select {
    font-weight: normal;
    margin-top: $size-16;
    box-shadow: 0 10px 20px -15px $ink;
    position: absolute;
    background-color: $base-cool;
    z-index: 1;
    max-height: $size-330;
    overflow: auto;
    outline: 1px solid $base-lighter;
    border-radius: $size-8;
    width: 100%;

    &[aria-expanded="true"] {
      display: block;
    }

    .path-title,
    button {
      display: block;
      width: 100%;
    }

    .path-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background: $base-lighter;
      padding: 0.5rem $size-16;
    }

    button {
      padding: $size-16;

      @media screen and (min-width: ($tablet)) {
        padding: $size-16;
      }

      &:nth-of-type(2n - 1) {
        background: $base-lightest;
      }

      &:hover {
        @media screen and (min-width: ($tablet)) {
          background: $purple;
        }
      }
    }
  }
}
