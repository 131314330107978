.bar {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

.inline {
  display: inline-block;
}

.tooltip {
  box-shadow: 0 0 10px $base-lighter !important;
  border: 1px solid $base-lighter !important;
  opacity: 1 !important;
  font-size: $size-16 !important;
  width: 250px !important;
  padding: 12px !important;
  line-height: 1.3rem !important;
}

.stat-block {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 8px;
}

.countyBlock {
  background-color: #f2e4d4;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  a {
    &:visited {
      color: $blue;
    }
    color: $blue;
  }

  h2 {
    margin: 0.5rem 0;
    font-weight: 600;
  }
}

.stat-block-stack {
  display: flex;
  flex-direction: column;
  max-width: $mobile-md;
}

.stat-block-container {
  display: flex;
  flex-direction: column;
}

.stat-block-number {
  padding-top: 1rem;
  font-weight: 700;
}

.break-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}

.custom-tooltip {
  text-transform: none;
  max-width: 300px;
}

#contact-provider-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  font-size: 0.85rem;

  .provider-btn-container {
    text-align: center;
  }

  .provider-no-url {
    display: flex;
  }

  .icon-container {
    margin: auto 0;
    margin-right: 0.75rem;
    svg {
      font-size: 35px;
    }
  }

  .usa-button {
    font-size: 0.8rem;
    background-color: $blue-60v;
    margin-bottom: 0.5rem;
  }
}

// small and up
@media (min-width: $tablet-md) {
  .stat-block {
    margin-top: 0rem;
    width: 190px;
  }

  .stat-block-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .stat-block + .stat-block {
    margin-left: 0.5rem;
  }

  .bar {
    margin-left: 0;
    margin-right: 0;
  }

  .grouping {
    border: 1px solid $light-grey;
    border-radius: 5px;
  }

  .group-padding {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  }
}
.title-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: ($tablet-md - 1)) {
    flex-direction: column-reverse;

    .save-controls {
      display: none;
    }
  }
}

.save-controls {
  display: flex;
  align-items: center;
  gap: $size-32;
  padding-top: $size-32;
  text-align: center;

  @media screen and (max-width: ($tablet-md - 1)) {
    padding-top: $size-8;
  }

  &.mobile-only {
    @media screen and (max-width: ($tablet-md - 1)) {
      display: flex;
      justify-content: center;
      padding-top: $size-22;
    }
  }

  li {
    button {
      gap: $size-4;
      display: flex;
      flex-direction: column;
      font-weight: 400;

      &:hover {
        svg,
        span {
          color: $black;
        }
      }
    }
  }
}

.funding-content {
  display: flex;
  flex-direction: column;
  gap: $size-24;
  padding-top: $size-4;

  @media screen and (min-width: ($tablet-md)) {
    max-width: calc(100% - $size-120);
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: $size-8;
    align-items: flex-start;
  }

  p,
  a {
    margin: 0;
  }

  a {
    margin: 0;
    width: auto;
    text-align: left;
  }
}

.group-wrapper {
  .grouping {
    @media screen and (max-width: ($tablet-md - 1)) {
      display: flex;
      flex-direction: column;

      .bar {
        position: sticky;
        top: 0;
      }
    }
  }
}

.training-detail {
  button {
    &.toggle {
      border-bottom: $size-2 dashed $black;
      padding: 0;
      margin-bottom: $size-8;
    }
  }

  .panel,
  .overlay {
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  .panel {
    position: fixed;
    border-left: $size-4 solid $secondary-dark;
    top: 0;
    right: -100%;
    background: $secondary-lighter;
    padding: 3rem;
    max-width: $size-520;
    width: 100%;
    height: 100%;
    z-index: 12;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: ($tablet - 1)) {
      padding: 3rem $size-16;
    }

    &.open {
      right: 0;
    }

    h3 {
      font-size: $size-22;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: $size-16;
      cursor: pointer;
      z-index: 11;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: $ink;
    opacity: 0;
    z-index: 9;

    &.open {
      pointer-events: all;
      opacity: 0.5;
    }
  }
}
