.single-path {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: $size-28;
  overflow: auto;
  padding: $size-8 $size-2;
  -webkit-overflow-scrolling: touch;

  .arrow {
    width: 0;
    height: 0;
    border-top: $size-10 solid transparent;
    border-bottom: $size-10 solid transparent;
    display: block;
    position: absolute;
    top: 50%;
    right: calc(100% + 0px);
    transform: translateY(-50%);
    z-index: 10;
    border-top: $size-5 solid $gray-cool-5;
    border-bottom: $size-5 solid $gray-cool-5;
    border-left: $size-10 solid $base;
  }

  li {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &:not(.tall) {
      &:first-of-type {
        .path-stop {
          &:after {
            display: none;
          }
        }
      }
    }

    &:first-of-type {
      .path-stop {
        &:before {
          content: none;
        }
        .arrow {
          display: none;
        }
      }
    }

    &:last-of-type {
      .path-stop {
        &:after {
          content: none;
        }

        .path-connector {
          display: none !important;
        }
      }
    }

    .path-connector {
      position: absolute;
    }

    + li {
      &.tall {
        margin-left: $size-30;
        .path-stop {
          .prev-path-connector {
            right: calc(100% + ($size-24 - $size-1));
          }

          &:first-of-type,
          &:last-of-type {
            .path-connector {
              left: calc(100% + ($size-24 - $size-1));
            }
          }

          .path-connector {
            left: calc(100% + ($size-24 - $size-1));
          }
        }

        + .tall {
          margin-left: 0;

          .path-stop {
            .prev-path-connector {
              right: calc(100% + ($size-28 + $size-1));
            }
          }
        }
      }

      &:not(.tall) {
        + li {
          &:not(.tall) {
            .path-stop {
              &.active,
              &:hover {
                .prev-path-connector {
                  right: calc(100% + $size-3);
                }
              }

              &.active {
                background: $tertiary;
                color: $gray-cool-5;
                border-color: $tertiary;
              }

              .prev-path-connector {
                display: block;
                height: $size-10;
                background-color: $gray-cool-5;
                width: $size-4;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
                right: calc(100% + $size-2);
              }

              &:after {
                background: $gray-cool-5;
              }
            }
          }
        }

        .path-stop {
          &:after {
            background: $base;
          }
          .arrow {
            display: block;
            right: calc(100% + $size-6);
          }
        }
      }
    }

    &.tall {
      position: relative;
      margin-right: $size-30;

      .path-stop {
        .arrow {
          right: calc(100% + $size-6);
        }
      }

      &:first-of-type {
        .prev-path-connector {
          display: none;
        }
      }

      + .tall {
        margin-left: 0;

        .path-stop {
          .prev-path-connector {
            right: calc(100% + ($size-28 + $size-1));
          }
        }
      }

      + li:not(.tall) {
        .path-stop {
          .arrow {
            right: calc(100% + $size-6);
          }
        }
      }

      .prev-path-connector {
        width: $size-10;
        background-color: $base;
        display: block;
        height: 135%;
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + ($size-26 + $size-1));
        z-index: 10;
        position: absolute;
      }

      .path-stop {
        position: relative;

        &:first-of-type {
          .path-connector,
          .prev-path-connector {
            top: calc(50% - $size-5);
            transform: none;
            height: 100%;
          }
        }

        &:last-of-type {
          .path-connector,
          .prev-path-connector {
            top: auto;
            height: 100%;
            transform: none;
            bottom: calc(50% - $size-5);
          }
        }

        &:before {
          width: ($size-16 + $size-1);
          right: calc(100% + $size-16);
        }

        &:after {
          width: $size-26 + $size-1;
        }

        .path-connector {
          width: $size-10;
          background-color: $base;
          display: block;
          height: 135%;
          top: 50%;
          transform: translateY(-50%);
          left: calc(100% + ($size-26 - $size-3));
          z-index: 10;
        }
      }

      + li {
        &:not(.tall) {
          .path-stop {
            &:before {
              width: $size-20;
              right: calc(100% + $size-10);
            }
          }
        }
      }
    }

    .prev-path-connector {
      display: none;
    }
  }

  .path-stop {
    border: 2px solid $text;
    border-radius: 0.3rem;
    background-color: $secondary-lighter;
    width: $size-200;
    min-height: $size-120;
    color: $text;
    padding: 0.3rem 0.8rem;
    position: relative;

    &:hover {
      border-color: $tertiary;
      outline: 1px solid $tertiary;
    }

    &.active {
      background: $tertiary;
      color: $gray-cool-5;
      border-color: $tertiary;
      outline: 1px solid $tertiary;
    }

    &:before {
      content: "";
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      width: $size-10;
      height: $size-10;
      display: block;
      background: $base;
      right: calc(100% + $size-16);
    }

    &:after {
      content: "";
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      width: calc($size-26 + $size-5);
      height: $size-10;
      display: block;
      background: $base;
      left: calc(100% + $size-6);
    }

    p {
      margin: 0;
      font-size: 0.8rem;
      line-height: 1.4;
    }

    .title {
      line-height: 1.2;
      font-size: $size-16;
      text-align: center;
      margin: 0.2rem 0 0.6rem;
    }

    .salary {
      margin: 0 0 0.3rem;
    }
  }
}
