.financial-resource {
  margin-top: 1.1rem;

  @media screen and (max-width: ($tablet-md)) {
    border: $size-1 solid $base-lighter;
    padding: 1.5rem;
    border-radius: 5px;
  }

  @media screen and (min-width: ($tablet-md + 1)) {
    padding: 1.5rem 0 1.2rem;
    border-top: $size-1 solid $base-lighter;
  }

  .heading {
    font-size: 1.3rem;
    margin-bottom: $size-16;
    font-weight: bold;
  }

  .details {
    margin-bottom: 2rem;
  }

  .tags {
    margin-bottom: 0.5rem;

    span {
      padding: $size-3 $size-8 2.5px;
      margin: 0 0.65rem 0.5rem 0;
      display: inline-block;
    }
  }

  .link {
    display: inline-block;
    margin-top: 0.3rem;

    span {
      display: flex;
      align-items: center;

      > span {
        font-size: $size-20;
        margin-left: 0.5rem;
      }
    }
  }
}
