.filterControls {
  width: 100%;

  .labelBox {
    .content {
      background-color: $lighter-green;
      width: 100%;

      @media screen and (max-width: ($tablet-lg - 1)) {
        border: $size-1 solid $base-lighter;
      }

      &.open {
        @media screen and (max-width: ($tablet-lg - 1)) {
          position: absolute;
          left: 0;
          max-height: calc(100vh - 41px);
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        }
      }
    }

    .group-heading {
      justify-content: center;
    }
  }

  .group-heading {
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0;
  }

  .heading {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: $size-16 0;
  }

  .clear {
    margin: $size-16 0 0;
  }

  .clear-all {
    padding: 0.8rem 1.3rem;
  }

  button {
    width: auto;
  }

  label:before,
  .usa-checkbox {
    background-color: transparent;
  }

  .usa-checkbox__input:checked + [class*="__label"]::before {
    background-color: $navy;
    box-shadow: 0 0 0 $size-2 $navy;
  }

  .usa-checkbox__input:focus + [class*="__label"]::before {
    outline: 0.25rem solid $navy;
  }

  .usa-button--outline {
    background-color: transparent;
    box-shadow: inset 0 0 0 $size-2 $navy;
    color: $navy;
    border-radius: 5px;

    &:hover {
      box-shadow: inset 0 0 0 $size-2 $primary;

      color: $primary;
    }

    &:focus {
      outline: 0.25rem solid $navy;
    }
  }

  .usa-button.usa-button--unstyled {
    color: $navy;

    &:focus {
      outline: 0.25rem solid $navy;
    }
  }
}
