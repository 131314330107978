footer {
  background-color: $footer-grey;
  color: white;
  padding: 1.5rem 0 0;
  min-height: 690px;

  @media screen and (max-width: ($tablet-md)) {
    min-height: 1600px;
  }

  > .container {
    @media screen and (min-width: ($tablet-md + 1)) {
      padding-bottom: $size-16;
      display: flex;
      max-width: $desktop-xl;
      width: 100%;
      justify-content: space-between;
    }

    > div {
      &:first-of-type {
        @media screen and (min-width: ($tablet-md + 1)) {
          width: calc(100% - 250px);
          margin-right: $size-40;
        }
      }

      &:last-of-type {
        @media screen and (min-width: ($tablet-md + 1)) {
          width: 220px;
        }

        @media screen and (max-width: ($tablet-md)) {
          padding-bottom: 2rem;
        }
      }
    }
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      @media screen and (min-width: ($tablet-md + 1)) {
        text-decoration: underline;
        color: $light-grey;
      }
    }

    &:visited {
      color: $white;
    }
  }

  .footer-link-section-header {
    span {
      border-bottom: $size-1 solid $white;
      display: block;
      margin-bottom: $size-8;
      padding-bottom: $size-8;
      width: 100%;
    }

    ul {
      span {
        border-bottom: 0;
      }
    }
  }

  .nav-heading {
    border-bottom: $size-1 solid $white;
    display: block;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
  }

  .nav-item {
    font-size: $size-14;

    > a,
    .nav-header {
      border-bottom: $size-1 solid $white;
      display: block;
      padding-bottom: 0.5rem;
      margin-bottom: $size-8;
    }

    > ul {
      padding: 0 0 2rem;

      li {
        margin-bottom: 0.3rem;
      }
    }
  }

  .no-sub {
    margin-bottom: 2.2rem;
  }

  .footer-nav-l {
    > div {
      > ul {
        @media screen and (min-width: ($tablet-md + 1)) {
          column-count: 2;
          column-gap: 1.25rem;
          perspective: 1;
        }

        @media screen and (min-width: ($desktop + 1)) {
          column-count: 3;
        }
      }
    }

    .nav-item {
      .material-icons {
        font-size: $size-14;
        margin-left: $size-2;
        padding-top: $size-2;
      }

      @media screen and (min-width: ($tablet-md + 1)) {
        break-inside: avoid-column;
      }
    }
  }
  .footer-nav-r {
    margin-bottom: $size-16;
    .nav-heading {
      margin-bottom: 1.5rem;
    }

    .nav-item {
      font-size: $size-14;
      .material-icons {
        font-size: $size-14;
        margin-left: $size-2;
        padding-top: $size-2;
      }

      > a {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0.3rem;
      }
    }

    .no-sub {
      margin-bottom: 0;
    }
  }
}
