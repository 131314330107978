.search-block {
  background-color: $secondary-lighter;
  padding: $size-32 $size-16;
  border: 1px solid $black;
  border-radius: $size-16;

  @media screen and (max-width: ($tablet-md)) {
    padding: $size-24 $size-16 $size-16;
  }

  form {
    max-width: $tablet-xl;
    margin: 0 auto;
  }

  .row {
    margin: 0;

    @media screen and (max-width: ($tablet-md)) {
      flex-direction: column;
      gap: $size-8;
    }
  }

  .heading-tag {
    text-align: left;
    font-weight: bold;
    font-size: $size-18;
    margin-bottom: $size-8;

    @media screen and (min-width: ($tablet-md)) {
      font-size: $size-24;
      margin-bottom: $size-16;
      width: calc(100% - 70px);
    }
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .heading-tag {
      display: flex;
      align-items: center;
      gap: $size-8;

      svg {
        color: $primary;
      }

      button {
        &.unstyled {
          width: auto;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    button:not(.usa-tooltip__trigger) {
      text-align: right;
      width: 70px;

      @media screen and (max-width: ($tablet-md)) {
        display: none !important;
      }
    }
  }

  .row,
  .checks {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  input,
  select {
    padding: 0.5rem;
    border-radius: 5px;
    height: 45px;
    border: none;
  }

  .usa-button:not(.usa-button--unstyled) {
    background-color: $green;
    font-size: 1.3rem;
    font-weight: normal;
  }

  .search-input {
    width: calc(100% - 175px);
    max-width: none;
    height: 45px;
    margin: 0;

    @media screen and (max-width: ($tablet-md)) {
      width: 100%;
    }
  }

  .submit {
    width: 100%;

    @media screen and (min-width: ($tablet-md)) {
      width: 175px;
      padding-left: $size-16;
    }

    button.usa-button {
      background-color: $secondary;
      padding: $size-12;
      font-size: $size-16;
      font-weight: bold;
      height: $size-45;
    }

    button,
    a {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      + a {
        margin-top: $size-16;
      }
    }
  }

  .filters {
    margin-top: $size-20;

    @media screen and (max-width: ($tablet-md )) {
      display: none;
    }

    input,
    select {
      height: $size-40;
      margin-top: 0.5rem;

      &[disabled] {
        background-color: $base-lightest;
      }
    }

    .row {
      @media screen and (max-width: ($tablet-xl - 1)) {
        flex-direction: column;
      }
    }

    .area {
      position: relative;

      &.extend {
        input {
          width: 323px;
        }
      }

      .red {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
      }
    }

    .inputs {
      display: flex;
      gap: $size-16;
      align-items: center;

      input,
      select {
        width: 8rem;

        @media (max-width: ($tablet-xl - 1)) and (min-width: ($tablet)) {
          width: 15rem;
        }

        @media screen and (max-width: ($tablet - 1)) {
          width: 100%;
        }
      }
    }

    .cost {
      display: flex;
      flex-direction: column;
      position: relative;

      @media screen and (max-width: ($tablet-xl - 1)) {
        margin: 2rem 0;
      }

      @media screen and (min-width: ($tablet-xl)) {
        align-items: center;
        padding: 0 3rem;
      }

      svg {
        position: absolute;
        top: 2.35rem;
        left: 3.5rem;

        @media screen and (max-width: ($tablet-xl - 1)) {
          left: 0.5rem;
        }
      }

      .label {
        text-align: left;
        width: 100%;
      }

      input {
        width: 20rem;
        margin-bottom: $size-16;
        padding-left: 2rem;

        @media screen and (max-width: ($tablet - 1)) {
          width: 100%;
        }
      }
    }

    .usa-checkbox {
      background-color: transparent;

      + .usa-checkbox {
        margin-left: $size-16;
      }
    }
  }
}
