.path-menu {
  background-color: $lightest-grey;
  border-radius: $size-20;
  padding: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .buttons,
  h4 {
    width: 50%;
  }

  ul {
    width: 100%;
  }

  h4 {
    font-weight: bold;
    order: 1;
  }

  .buttons {
    order: 2;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 0.6rem 0.6rem 0.4rem;

      &:first-child {
        background-color: $navy;
      }
    }
  }

  ul {
    order: 3;
  }
}
