.resource-filter {
  @media screen and (min-width: ($tablet-md + 1)) {
    padding: 2rem 0;
  }
  .container {
    @media screen and (min-width: ($tablet-md + 1)) {
      width: 100%;
      max-width: $desktop-xl;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  .resource-controller {
    @media screen and (max-width: ($tablet-md)) {
      margin: 0 -15px;
      position: relative;
    }

    @media screen and (min-width: ($tablet-md + 1)) {
      max-width: 292px;
      width: 100%;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      overflow: hidden;
      position: sticky;
      top: $size-16;
    }

    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $size-16 15px;
      width: 100%;
      background-color: $green;
      color: white;
      font-size: 1.4rem;

      @media screen and (min-width: ($tablet-md + 1)) {
        padding: 2rem;
      }

      span {
        transform: rotate(90deg);

        @media screen and (min-width: ($tablet-md + 1)) {
          display: none;
        }
      }
    }

    .inner {
      @media screen and (max-width: ($tablet-md)) {
        overflow: hidden;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
      }

      @media screen and (min-width: ($tablet-md + 1)) {
        height: auto !important;
      }

      form {
        width: 100%;
        max-width: none;
        padding: $size-16 15px;
        border-bottom: $size-1 solid $ink;

        @media screen and (min-width: ($tablet-md + 1)) {
          border-left: $size-1 solid;
          border-right: $size-1 solid;
          border-color: $green;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          padding: 2rem;
        }
      }

      button {
        display: block;
        margin: 0 0 1.2rem;
        padding: 0;

        &.reset {
          margin: 1.5rem 0.5rem 0;
          color: $accessible-gray;
          border-bottom: $size-1 solid $accessible-gray;
        }
      }
    }

    .usa-legend {
      font-size: 1.4rem;
    }
  }

  .content {
    @media screen and (max-width: ($tablet-md)) {
      margin-top: 1.8rem;
    }

    @media screen and (min-width: ($tablet-md + 1)) {
      width: calc(100% - 292px);
      padding: 3rem 0 0 1.7rem;
    }

    .showing {
      font-weight: bold;
      font-size: 1.4rem;
    }

    .usa-tag {
      text-transform: none;

      &.green {
        background-color: $green;
      }
      &.blue {
        background-color: $primary;
      }
      &.red {
        background-color: $red;
      }
      &.yellow {
        background-color: $tag-yellow;
        color: $ink;
      }
      &.navy {
        background-color: $tag-navy;
      }
      &.black {
        background-color: $ink;
      }
      &.gray {
        background-color: $tag-gray;
      }
    }
  }
}
