.resource-links {
  background-color: $light-purple;
  padding: 1.5rem;
  border-radius: $size-4;
  font-size: 1.22rem;
  margin-top: 3rem;

  .heading {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    margin-bottom: 1.6rem;
  }

  a {
    color: $primary;
    letter-spacing: 0.3px;
    text-decoration: none;
    display: inline-block;
    border-bottom: $size-2 solid $primary;

    &:visited,
    &:hover {
      color: $primary;
      border-bottom: $size-2 solid $primary;
    }
  }
}
