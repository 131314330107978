.backToTop {
  position: fixed;
  bottom: $size-16;
  cursor: pointer;
  right: $size-16;
  background: white;
  width: $size-48;
  height: $size-48;
  border-radius: $size-4;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 5;
  color: $primary;
  border: 1px solid $primary;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:hover {
    @media screen and (min-width: ($tablet-lg)) {
      outline: $size-2 solid $primary-light;
    }
  }

  &.buttonHide {
    opacity: 0;
    pointer-events: none;
  }
}
