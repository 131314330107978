.page-banner {
  background-color: $base-cool;
  padding: 3.1rem 0 0.7rem;

  @media screen and (max-width: ($mobile-lg)) {
    padding: 0;
  }

  .detail-page & {
    background-color: $white;
  }

  .top-nav {
    padding-bottom: 0.5rem;
    border-bottom: $size-1 solid $base-lighter;

    @media screen and (min-width: ($mobile-lg + 1)) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $size-16;
    }

    @media screen and (max-width: ($mobile-lg)) {
      padding-bottom: 0;
    }

    a {
      color: $primary-vivid;
      text-decoration: underline;

      &:hover {
        @media screen and (min-width: ($tablet-lg)) {
          color: $primary;
        }
      }
    }
  }

  .date {
    width: 140px;
    font-size: 0.8rem;
    line-height: 1.5;

    @media screen and (max-width: ($mobile-lg)) {
      padding: 0 0 0.5rem;
      width: 100%;
    }
  }

  h1 {
    font-size: 3.7rem;

    margin: 0 0 $size-16;
    padding: 0;
    line-height: 1;
    @media screen and (max-width: ($mobile-lg)) {
      font-size: 2.45rem;
      margin-bottom: $size-16;
    }
  }

  .copy {
    padding: $size-48 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    gap: 3rem;

    @media screen and (max-width: ($tablet-xl - 1)) {
      display: block;
    }

    @media screen and (max-width: ($mobile-lg)) {
      padding: $size-24 0;
      font-size: $size-16;
    }
  }

  .heading {
    width: calc(100% - 335px);
    line-height: 0.4;
    @media screen and (max-width: ($tablet-xl - 1)) {
      width: 100%;
    }
  }

  .cta-block {
    text-align: center;
    width: 260px;
    font-weight: bold;
    font-size: 1.4rem;

    @media screen and (max-width: ($tablet-xl - 1)) {
      width: 100%;
      max-width: $size-320;
      margin: 2rem 0 0;
    }

    @media screen and (max-width: ($mobile-lg - 1)) {
      max-width: none;
    }

    > p {
      line-height: 1.1;
      margin-bottom: $size-16;
    }

    a {
      display: block !important;
      padding: $size-16;
    }

    li {
      + li {
        margin: $size-16 0 0;

        a {
          color: $secondary-dark;
        }
      }
    }
  }

  &.theme-green {
    .cta-block {
      li {
        a:not(.usa-button--outline) {
          &.usa-button {
            background-color: $secondary;

            &:hover {
              background-color: $secondary-dark;
            }
          }
        }

        a.usa-button--outline {
          box-shadow: inset 0 0 0 2px $secondary-dark;
          color: $secondary-dark;

          &:hover {
            box-shadow: inset 0 0 0 2px $secondary-darker;
            color: $secondary-darker;
          }
        }
      }
    }
  }

  &.theme-blue {
    .cta-block {
      li {
        a:not(.usa-button--outline) {
          &.usa-button {
            background-color: $primary;

            &:hover {
              background-color: $primary-dark;
            }
          }
        }
        a.usa-button--outline {
          box-shadow: inset 0 0 0 2px $primary-dark;
          color: $primary-dark;

          &:hover {
            box-shadow: inset 0 0 0 2px $primary-darker;
            color: $primary-darker;
          }
        }
      }
    }
  }

  &.theme-purple {
    .cta-block {
      li {
        a:not(.usa-button--outline) {
          &.usa-button {
            background-color: $tertiary-dark;

            &:hover {
              background-color: $tertiary;
            }
          }
        }
        a.usa-button--outline {
          box-shadow: inset 0 0 0 2px $tertiary-darker;
          color: $tertiary-darker;

          &:hover {
            box-shadow: inset 0 0 0 2px $tertiary;
            color: $tertiary;
          }
        }
      }
    }
  }

  &.theme-navy {
    .cta-block {
      li {
        a:not(.usa-button--outline) {
          &.usa-button {
            background-color: $info-darker;

            &:hover {
              background-color: $primary;
            }
          }
        }

        a.usa-button--outline {
          box-shadow: inset 0 0 0 2px $info-darker;
          color: $info-darker;

          &:hover {
            box-shadow: inset 0 0 0 2px $primary;
            color: $primary;
          }
        }
      }
    }
  }
}
