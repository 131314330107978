h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: $size-16;
  line-height: 1.1;
}

.text-xxl {
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.1;
}

.text-xl {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.1;
}

.text-l {
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
}

.text-m {
  font-style: normal;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.2;
}

.text-s {
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.2;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.all-caps {
  text-transform: uppercase;
}

.lhxl {
  line-height: 2rem;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

.underline {
  text-decoration: underline;
}

@media (min-width: $tablet-xl) {
  .text-xxl {
    font-size: 4rem;
  }

  .text-xl {
    font-weight: 500;
    font-size: 2rem;
  }

  .weight-400 {
    font-weight: 400;
  }

  .weight-500 {
    font-weight: 500;
  }
}
