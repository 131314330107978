.pathway-menus {
  .heading {
    margin-bottom: $size-16;

    h3 {
      font-size: 2rem;
      font-weight: bold;
      padding-bottom: $size-16;
      margin-top: 4rem;
      border-bottom: 0.5rem solid $ink;
    }
  }
}
