.drop-selector {
  display: none;
  width: 100%;
  padding: 0.6rem 0.4rem;
  font-weight: normal;
  color: $dark-green;
  border: $size-1 solid $dark-green;
  border-radius: 0;

  @media screen and (max-width: ($tablet-md)) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
