.industry-field-drawer {
  .panel {
    position: fixed;
    border-left: $size-4 solid $tertiary-vivid;
    top: 0;
    right: -100%;
    background: $white;
    padding: 3rem;
    max-width: 600px;
    width: 100%;
    height: 100%;
    z-index: 12;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: ($tablet - 1)) {
      padding: 3rem $size-16;
    }

    &.open {
      right: 0;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: $size-16;
      cursor: pointer;
      z-index: 11;
    }
  }

  .panel,
  .overlay {
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: $ink;
    opacity: 0;
    z-index: 11;

    &.open {
      pointer-events: all;
      opacity: 0.5;
    }
  }

  .title {
    font-size: $size-18;
    font-weight: bold;
    line-height: 1.3;
  }
}
