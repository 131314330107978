.industry-block {
  button {
    color: $ink;
  }

  .panel,
  .overlay {
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  .panel {
    position: fixed;
    border-left: $size-4 solid $tertiary-vivid;
    top: 0;
    right: -100%;
    background: $white;
    padding: 3rem;
    max-width: 800px;
    width: 100%;
    height: 100%;
    z-index: 12;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media screen and (max-width: ($tablet - 1)) {
      padding: 3rem $size-16;
    }

    &.open {
      right: 0;
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: $size-16;
      cursor: pointer;
      z-index: 11;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background: $ink;
    opacity: 0;
    z-index: 11;

    &.open {
      pointer-events: all;
      opacity: 0.5;
    }
  }

  .container {
    margin-top: 3.1rem;

    &.button {
      margin: 0 auto;
    }
  }

  .photo {
    margin: 1.5rem 0;
    width: 100%;

    @media screen and (max-width: ($tablet)) {
      margin-bottom: $size-16;
    }
  }

  .accordion {
    border: $size-1 solid $base-lightest;
    border-radius: 7px;
    overflow: hidden;
    background: $white;

    &.closed {
      button {
        &:before {
          transform: rotate(-45deg);
          right: $size-18;
        }
        &:after {
          transform: rotate(45deg);
        }
      }
    }

    button {
      font-size: 1.2rem;
      padding: 0.7rem 3rem 0.7rem 1.2rem;
      font-weight: normal;
      background-color: $secondary-lighter;

      img,
      svg {
        height: $size-32;
        width: auto;
      }

      span {
        width: calc(100% - $size-32);
      }

      &:before,
      &:after {
        width: 10px;
      }
      &:before {
        transform: rotate(45deg);
        right: $size-18;
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .content {
    .inner {
      padding: $size-16;
      max-width: none;

      ol {
        list-style: none;
        padding: 0;

        li {
          display: flex;
          gap: 1.5rem;
          align-items: center;

          @media screen and (max-width: ($mobile-lg - 1)) {
            flex-direction: column;
            align-items: flex-start;
          }

          + li {
            margin-top: $size-16;
          }

          b {
            display: block;
          }
        }
      }
    }
  }

  .heading {
    margin-bottom: 1rem;

    @media screen and (max-width: ($tablet)) {
      margin-bottom: 1.5rem;
    }

    h2 {
      font-size: $size-32;
      margin-bottom: 0;

      @media screen and (max-width: ($tablet - 1)) {
        font-size: 1.5rem;
      }
    }
  }
}

.explore-button {
  background-color: $primary-lighter;
  display: flex;
  font-size: $size-14;
  align-items: center;
  color: $ink;
  margin-bottom: $size-16;
  gap: $size-8;
  padding: $size-8 $size-16;
  border-radius: $size-4;

  @media screen and (max-width: ($tablet - 1)) {
    strong {
      display: none;
    }
  }

  &:hover {
    box-shadow: $shadow-2;
  }
}
