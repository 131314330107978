$gap: $size-60;
$boxHeight: $size-100;
$boxWidth: $size-120;
$boxWidthHalf: $size-60;
$fontSize: $size-14;
$borderWidth: $size-6;
$borderWidthHalf: $size-3;
$gapThird: $size-20;
$gapHalf: $size-30;

.career-map-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  overflow: auto;
  width: 100%;
  max-width: 1170px;
  -webkit-overflow-scrolling: touch;
}

.career-map {
  width: 1100px;
  display: flex;
  align-items: center;
  gap: $gap;
  position: relative;
  margin: $boxWidthHalf 0;

  .medium-map & {
    margin: $boxWidth 0 $boxWidthHalf;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: $gap;
    align-items: flex-start;
  }

  li {
    align-items: center;
    display: flex;
    gap: $gap;
    position: relative;

    &:not(.level-2) {
      align-items: flex-start;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: #909090;
      height: calc(100% - ($boxWidth - $gapThird));
      left: $boxWidth + ($boxWidthHalf) - $gapHalf;
      top: 50%;
      transform: translateY(-50%);
      width: $borderWidth;
      display: block;
    }

    &.level-2 {
      gap: $gap;

      > .info {
        > .box {
          &:before {
            width: $boxWidth + $borderWidth - $borderWidthHalf;
          }
        }
      }

      &:first-of-type,
      &:last-of-type {
        margin-left: -$boxWidth - $gap;

        > .info {
          > .box {
            &:before {
              content: none;
            }
          }
        }

        .small-map & {
          margin-left: 0;

          > .info {
            > .box {
              &:before {
                content: "";
              }
            }
          }
        }
      }

      &:first-of-type {
        .medium-map & {
          bottom: $boxWidthHalf + $gapHalf;
        }
      }
      &:last-of-type {
        .medium-map & {
          bottom: $boxWidthHalf + $gapHalf;
          margin-left: 0;

          > .info {
            > .box {
              &:before {
                content: "";
              }
            }
          }
        }
      }
    }

    &.no-children {
      gap: 0;
      .box {
        &:after {
          content: none;
        }
      }
    }
  }

  .box {
    height: $boxHeight;
    width: $boxWidth;
    background-color: #ebf5ee;
    border-radius: 10px;
    border: $borderWidthHalf solid $ink;
    font-size: $fontSize;
    text-align: center;
    padding: 5px;
    display: flex;
    justify-content: center;

    position: relative;
    align-items: center;
    z-index: 1;

    .inner {
      p {
        font-size: 0.6rem;
      }
    }

    &.main {
      z-index: 2;
      background: $ink;
      color: white;
      border-radius: 100%;
      height: $boxWidth;
      &:before,
      &:after {
        content: none;
      }

      svg {
        width: auto;
        height: 45px;
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: #909090;
      height: $borderWidth;
      width: ($boxWidthHalf) - $gapHalf;

      display: block;
      z-index: -1;
    }
    &:before {
      right: calc(100% + $borderWidthHalf);
    }
    &:after {
      left: calc(100% + $borderWidthHalf);
    }

    .filler {
      position: absolute;
      background-color: #909090;
      display: block;
      width: $borderWidth;
      left: 50%;
      top: calc(100% + $borderWidthHalf);
      transform: translateX(-50%);
    }
  }

  .line {
    width: ($boxWidthHalf) + $borderWidth;
    position: absolute;
    height: $borderWidth;
    background-color: #909090;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    &.above {
      > div {
        bottom: calc(50% - $size-3);
        top: auto;
      }
    }
    &.below {
      > div {
        top: calc(50% - $size-3);
        bottom: auto;
      }
    }

    &.left {
      left: auto;
      right: 100%;
    }

    > div {
      display: block;
      background-color: #909090;
      position: absolute;
      bottom: 50%;
      width: $borderWidth;
    }
  }
}
