.cta-collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $size-48;

  .ctaBanner {
    padding-top: 0;
    padding-bottom: 0;

    .cta {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.how-to-steps-section {
  background-color: white;
  border-radius: $size-16;
  padding: $size-16;
  max-width: $desktop-lg - 50;
  margin: $size-32 auto;

  @media screen and (min-width: ($tablet-md + 1)) {
    border-radius: $size-80;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: $size-16;

    @media screen and (max-width: ($tablet-md)) {
      align-items: flex-start;
      flex-direction: column;
    }

    li {
      display: flex;
      align-items: center;
      gap: $size-8;
      font-size: $size-16;

      @media screen and (min-width: ($tablet-md + 1)) {
        font-size: $size-18;

        font-weight: 600;
      }

      p {
        line-height: 1;
      }
    }

    .list-num {
      align-items: center;
      background-color: $secondary;
      border-radius: 100%;
      color: $white;
      display: flex;
      font-size: $size-16;
      font-weight: bold;
      height: $size-24;
      justify-content: center;
      padding-top: $size-3;
      width: $size-24;
    }
  }
}

.training-explorer-heading {
  background-color: $base-lightest;

  p {
    line-height: 1;
  }

  .top-nav {
    margin: $size-32 0;
  }

  .heading-tag {
    text-align: center;
    font-size: $size-48;

    @media screen and (max-width: ($tablet-md)) {
      font-size: $size-40;
      margin-bottom: $size-32;
    }
  }

  .subheading {
    text-align: center;
    max-width: $tablet-lg;
    margin: $size-16 auto;
    line-height: 1.5;

    .heading {
      font-size: $size-32;

      @media screen and (max-width: ($tablet-md)) {
        font-size: $size-24;
      }
    }

    p {
      line-height: 1.2;

      @media screen and (min-width: ($tablet-md + 1)) {
        font-size: $size-18;
      }
    }
  }

  .learn-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $size-32 0;

    p {
      background-color: white;
      border-radius: $size-80;
      padding: $size-16;
      font-size: $size-18;
      display: inline-block;

      @media screen and (max-width: ($tablet-md)) {
        font-size: $size-16;
        border-radius: $size-16;
      }
    }
  }

  .cta {
    .heading-tag {
      font-size: $size-22;
      margin-bottom: $size-16;

      @media screen and (min-width: ($tablet-md + 1)) {
        font-weight: bold;
      }

      @media screen and (max-width: ($tablet-md)) {
        font-size: $size-18;
      }
    }

    .btns {
      display: flex;
      gap: $size-18;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: ($tablet-md)) {
        flex-direction: column;
        gap: $size-8;
      }

      a {
        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: $size-8;
        }

        @media screen and (max-width: ($tablet-md)) {
          padding: $size-16 $size-8;
          width: 100%;
        }
      }
    }
  }
}
