@mixin linkColor {
  color: $primary-vivid;

  &:visited {
    color: $primary-vivid;
  }

  &:hover {
    @media screen and (min-width: ($desktop)) {
      color: $primary;
    }
  }
}

@mixin unstyledButton {
  padding: 0;
  background-color: transparent;
  color: inherit;
  @include linkColor;
}

.usa-button {
  display: inline-flex !important;
  align-items: center;
  gap: $size-8;
  padding: $size-16 $size-24;

  &.custom-button {
    &.report {
      background-color: $accent-warm-lighter;
      color: $black;
      width: 100%;
      padding: $size-16;
      margin-top: $size-24;

      &:hover {
        @media screen and (min-width: ($tablet)) {
          background-color: $accent-warm;
        }
      }

      span {
        text-align: left;
        width: 100%;
      }
    }
  }

  &.usa-button--outline {
    box-shadow: none;

    &:focus,
    &:hover {
      outline-offset: 0;
      box-shadow: $shadow-3;
    }
  }

  &.primary {
    &:not(.usa-button--outline) {
      background: $primary;
      color: $white;
    }

    &.usa-button--outline {
      background: $white;
      outline: $size-2 $primary solid;
      color: $primary;

      &:hover,
      &:focus {
        @media screen and (min-width: ($tablet)) {
          outline-color: $primary-dark;
          color: $primary-dark;
          background-color: $white;
        }
      }
    }

    &:hover {
      @media screen and (min-width: ($tablet)) {
        background-color: $primary-dark;
        color: $white;
      }
    }

    &.unstyled,
    &.usa-button--unstyled {
      @include unstyledButton;
    }
  }

  &.secondary {
    &:not(.usa-button--outline) {
      background: $secondary;
      color: $white;
    }

    &.usa-button--outline {
      background: $white;
      outline: $size-2 $secondary solid;
      color: $secondary;

      &:hover,
      &:focus {
        @media screen and (min-width: ($tablet)) {
          outline-color: $secondary-dark;
          color: $secondary-dark;
          background-color: $white;
        }
      }
    }

    &:hover {
      @media screen and (min-width: ($tablet)) {
        background-color: $secondary-dark;
        color: $white;
      }
    }

    &.unstyled,
    &.usa-button--unstyled {
      @include unstyledButton;
    }
  }

  &.tertiary {
    &:not(.usa-button--outline) {
      background: $tertiary;
      color: $white;
    }

    &.usa-button--outline {
      background: $white;
      outline: $size-2 $tertiary solid;
      color: $tertiary;

      &:hover,
      &:focus {
        @media screen and (min-width: ($tablet)) {
          outline-color: $tertiary-dark;
          color: $tertiary-dark;
          background-color: $white;
        }
      }
    }

    &:hover {
      @media screen and (min-width: ($tablet)) {
        background-color: $tertiary-dark;
        color: $white;
      }
    }

    &.unstyled,
    &.usa-button--unstyled {
      @include unstyledButton;
    }
  }

  &.quaternary {
    &:not(.usa-button--outline) {
      background: $info-darker;
      color: $white;
    }

    &.usa-button--outline {
      background: $white;
      outline: $size-2 $info-darker solid;
      color: $info-darker;

      &:hover,
      &:focus {
        @media screen and (min-width: ($tablet)) {
          outline-color: $info-dark;
          color: $info-dark;
          background-color: $white;
        }
      }
    }

    &:hover {
      @media screen and (min-width: ($tablet)) {
        background-color: $info-dark;
        color: $white;
      }
    }

    &.unstyled,
    &.usa-button--unstyled {
      @include unstyledButton;
    }
  }

  &.quinary {
    &:not(.usa-button--outline) {
      background: $accent-warm;
      color: $white;
    }

    &.usa-button--outline {
      background: $white;
      outline: $size-2 $accent-warm solid;
      color: $accent-warm;
    }

    &:hover {
      @media screen and (min-width: ($tablet)) {
        background-color: $accent-warm-dark;
        color: $white;
      }
    }

    &.unstyled,
    &.usa-button--unstyled {
      @include unstyledButton;
    }
  }

  &[class*="highlight-"] {
    padding: $size-8 $size-16;
    border-radius: $size-8;
    border: $size-2 solid;

    &:hover {
      @media screen and (min-width: ($tablet)) {
        box-shadow: $shadow-3;
      }
    }

    span:not(.svg-container) {
      display: block;
      width: 100%;
    }

    svg,
    .svg-container {
      height: auto;
      width: 32px;
    }
  }

  &.centered-text {
    text-align: center;
    justify-content: center;
  }

  &.highlight-orange {
    background: $accent-warm-lighter;
    border-color: $accent-warm-light;
    color: $accent-warm-darker;

    &:hover {
      @media screen and (min-width: ($tablet)) {
        border-color: $accent-warm;
      }
    }
  }

  &.highlight-blue {
    background: $primary-lighter;
    border-color: $primary-light;
    color: $primary-darker;

    &:hover {
      @media screen and (min-width: ($tablet)) {
        border-color: $primary-vivid;
      }
    }
  }

  &.highlight-purple {
    background: $tertiary-lighter;
    border-color: $tertiary-light;
    color: $tertiary-darker;

    &:hover {
      @media screen and (min-width: ($tablet)) {
        border-color: $tertiary-vivid;
      }
    }
  }

  &.highlight-green {
    background: $secondary-lighter;
    border-color: $secondary-light;
    color: $secondary-darker;

    &:hover {
      @media screen and (min-width: ($tablet)) {
        border-color: $secondary-vivid;
      }
    }
  }

  &.highlight-navy {
    background: $info-lighter;
    border-color: $info-light;
    color: $info-darker;

    &:hover {
      @media screen and (min-width: ($tablet)) {
        border-color: $info;
      }
    }
  }

  &.info {
    background: $white;
  }

  &.info,
  &.svg:not([class*="highlight-"]) {
    padding: 0.6rem $size-16 $size-8;
    color: $ink;
    border-radius: $size-12;
    border: $size-1 solid $base-lighter;

    &:hover {
      background: $white;
      color: $ink;
      border-radius: $size-12;
      border: $size-1 solid $base-lighter;
      opacity: 1;

      @media screen and (min-width: ($desktop)) {
        transform: scale(1.05);
        box-shadow: $shadow-2;
      }
    }

    &.fill {
      .svg-container {
        background-color: $primary-lighter;
        border-radius: 100%;
        padding: $size-4;
        height: 30px;
        width: 30px;
      }
    }

    svg {
      height: 30px;
      width: auto;
      color: $info-darker;
    }
  }
  .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.unstyled,
  &.usa-button--unstyled {
    @include unstyledButton;
  }
}

.usa-button--unstyled {
  @include unstyledButton;
}
