// styles for all landing pages

@import "../colors";

.search-container {
  max-width: 580px;
}

.landing-image {
  img {
    width: 60px;
  }
}

.options-desc {
  max-width: 320px;
}

.landing-container {
  max-width: 555px;
}

.landing-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.landing-card-container {
  max-width: 427px;
}

.landing-card {
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &.hide-border-mobile {
    @media (max-width: $tablet-md) {
      border: 0;
      box-shadow: none;
    }
  }
}

@media (min-width: $tablet-xl) {
  .title {
    font-size: 1.5rem;
  }
}

.landing-faq {
  margin: 5rem 0;

  @media screen and (max-width: ($tablet-xl)) {
    margin-top: 3rem;
  }

  .heading-tag {
    padding-bottom: 0.6rem;
    margin-bottom: 3rem;

    @media screen and (max-width: ($tablet)) {
      margin-bottom: 2.5;
    }
  }

  .accordion {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
  }

  .cta {
    @extend .footer-cta;
  }

  .cta {
    margin-bottom: 0 !important;
  }
}
