.resourceCard {
  border-radius: $size-20;
  border: $size-2 solid $light-grey;
  padding: 1.5rem;

  > a {
    color: $primary;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    display: block;
    text-decoration: underline;

    &:hover,
    &:visited {
      color: $primary;
      opacity: 0.7;
    }
  }

  .tags {
    margin-top: $size-16;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
