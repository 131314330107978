.career-pathways-page {
  .landing {
    padding: $size-80 0;

    .sectionHeading {
      margin-bottom: $size-48;
    }
  }
  .banner {
    .inner {
      display: flex;
      flex-direction: column;
      gap: $size-64;
      padding: $size-32 0 $size-64;

      @media screen and (max-width: ($tablet-md - 1)) {
        padding: $size-16 0 $size-32;
        gap: $size-32;
      }

      @media screen and (max-width: ($mobile-lg - 1)) {
        padding-top: 0;
      }
    }

    .heading-tag {
      font-size: $size-32;
      display: inline-flex;
      align-items: center;
      gap: $size-16;
      cursor: default;

      @media screen and (max-width: ($tablet-md - 1)) {
        font-size: $size-22;
        flex-direction: column-reverse;
        align-items: flex-start;
        font-weight: bold;
      }

      span {
        font-size: $size-12;
        font-weight: normal;
      }
    }

    p {
      font-size: $size-22;
      margin: 0;
      @media screen and (max-width: ($tablet-md - 1)) {
        font-size: $size-18;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: $size-8;
    }
  }

  .body-copy {
    padding: $size-96 0;
    margin: 0 auto;

    .container {
      display: flex;
      flex-direction: column;
      gap: $size-48;

      @media screen and (max-width: ($tablet-md - 1)) {
        gap: $size-32;
      }

      @media screen and (max-width: ($mobile-lg - 1)) {
        gap: $size-16;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: $size-24;

      &.content-block {
        border-radius: $size-16;
        padding: $size-32;
      }

      &.section-1 {
        background-color: $primary-extra-light;
      }

      &.section-2 {
        background-color: $tertiary-extra-light;
      }

      &.section-3 {
        background-color: $secondary-extra-light;
      }

      * {
        margin: 0;
        line-height: 1.3;
      }
    }

    h2 {
      font-size: $size-32;

      @media screen and (max-width: ($tablet-md - 1)) {
        font-size: $size-22;
        font-weight: bold;
      }
    }

    h3 {
      font-size: $size-22;
      font-weight: bold;

      @media screen and (max-width: ($tablet-md - 1)) {
        font-size: $size-18;
        font-weight: bold;
      }
    }
  }

  .contact-cta {
    text-align: center;
    margin-bottom: $size-48;

    .inner {
      background-color: $info-lighter;
      border-radius: $size-8;
      display: flex;
      flex-direction: column;
      gap: $size-16;
      align-items: center;
      padding: $size-52 $size-16;
    }

    .heading-tag {
      font-size: $size-22;
      font-weight: bold;
      max-width: $size-300;
      margin: 0 auto;
    }

    .usa-button {
      width: auto;
    }
  }
}

.custom-tooltip {
  box-shadow: 0 0 10px $base-lighter !important;
  border: 1px solid $base-lighter !important;
  opacity: 1 !important;
  font-size: $size-16 !important;
  width: 250px !important;
  padding: 12px !important;
  line-height: 1.3rem !important;
}
