.resource-list {
  padding: 3rem 0;

  .labelBox {
    .title {
      justify-content: center;
    }
  }

  .container {
    display: flex;
    gap: 2rem;
    align-items: flex-start;

    @media screen and (max-width: ($tablet-lg - 1)) {
      flex-direction: column;
    }

    &:before,
    &:after {
      content: none;
    }

    .sidebar {
      position: sticky;
      top: 2rem;
      left: 0;
      width: 100%;

      @media screen and (min-width: ($tablet-lg)) {
        width: 411px;
      }

      @media screen and (max-width: ($tablet-lg - 1)) {
        top: 0;
        z-index: 1;
      }

      .footer-cta,
      .related {
        @media screen and (max-width: ($tablet-lg - 1)) {
          display: none;
        }
      }
    }

    .cards {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media screen and (min-width: ($tablet-lg)) {
        width: calc(100% - 411px);
      }

      .footer-cta,
      .related {
        @media screen and (min-width: ($tablet-lg)) {
          display: none;
        }
      }
    }
  }

  .footer-cta {
    margin-bottom: 0;

    .usa-button {
      background: $primary;

      &:hover {
        background: $navy;
      }
    }

    .container {
      flex-direction: column;
      width: 100%;
      margin: 0;
      align-items: center;
      gap: 0;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .list-heading {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: ($tablet-lg - 1)) {
      flex-direction: column;
    }

    > .label {
      font-size: $size-22;

      @media screen and (max-width: ($tablet-lg - 1)) {
        order: 1;
        margin-bottom: $size-16;
      }

      @media screen and (min-width: ($tablet-lg)) {
        text-align: right;
        width: 180px;
      }
    }

    > div {
      @media screen and (max-width: ($tablet-lg - 1)) {
        order: 2;
      }
      @media screen and (min-width: ($tablet-lg)) {
        width: calc(100% - 180px);
      }
    }

    .label {
      font-weight: bold;
    }

    .tags {
      margin-top: $size-16;
      display: flex;
      flex-wrap: wrap;
      gap: $size-16;

      button {
        padding: 0;
      }
    }

    .tag-item {
      padding: 0.5rem;
      border-radius: 0.3rem;
    }
  }

  .related {
    background-color: $dark-navy;
    color: $white;
    text-align: center;
    padding: 3rem 2rem;
    border-radius: 10px;
    margin-top: 3rem;

    h3 {
      font-size: $size-22;
      margin-bottom: 2rem;
    }

    .usa-button {
      text-align: left;
      background-color: $info-lighter;
      width: 100%;
      border: $size-2 solid $bright-blue;
      display: inline-flex;
      align-items: center;
      gap: $size-16;
      color: $navy;
      border-radius: 10px;
      padding: 0.5rem;

      + .usa-button {
        margin-top: 1.5rem;
      }

      @media screen and (max-width: ($tablet-xl - 1)) {
        display: flex;
        width: 100%;
      }

      &:hover {
        @media screen and (min-width: ($tablet-xl + 1)) {
          background: white;
        }
      }

      svg {
        width: $size-32;
        height: auto;
      }
    }
  }
}
