.iconCard {
  p {
    max-width: none;
  }

  a {
    border: $size-2 solid $base-lighter;
    border-radius: $size-16;
    padding: $size-16;
    color: $ink;
    background: $white;
    text-decoration: none;
    display: block;

    @media screen and (min-width: ($desktop)) {
      transition-duration: $transition-speed;
      transition-timing-function: ease-in-out;
      transition-property: transform, box-shadow, background-color, border-color;
    }

    &:visited {
      color: $ink;
    }

    &:hover {
      color: $ink;

      @media screen and (min-width: ($desktop)) {
        box-shadow: $shadow-3;
      }
    }
  }

  .icons {
    display: flex;
    justify-content: space-between;
    align-self: center;
  }

  .title {
    font-weight: bold;
    line-height: 1.2;
    margin: $size-12 0;
    font-size: $size-18;
  }

  .description {
    font-size: $size-14;
    line-height: $size-20;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  &.fill {
    .title {
      @media screen and (min-width: ($tablet)) {
        width: 75%;
        font-size: $size-26;
      }
    }

    a {
      border: $size-2 solid;

      &:hover {
        color: $white;
      }
    }
  }

  &.blue {
    a {
      border-color: $primary;
    }

    &.fill {
      a {
        background-color: $primary-lighter;

        &:hover {
          background-color: $primary;
        }
      }
    }
  }

  &.green {
    a {
      border-color: $secondary-light;
    }

    &.fill {
      a {
        background-color: $secondary-lighter;

        &:hover {
          background-color: $secondary-light;
        }
      }
    }
  }

  &.purple {
    a {
      border-color: $tertiary-vivid;
    }

    &.fill {
      a {
        background-color: $tertiary-lighter;

        &:hover {
          background-color: $tertiary-vivid;
        }
      }
    }
  }

  &.navy {
    a {
      border-color: $info-darker;
    }

    &.fill {
      a {
        background-color: $info-lighter;

        &:hover {
          background-color: $info-darker;
        }
      }
    }
  }

  &.orange {
    a {
      border-color: $accent-warm;
    }

    &.fill {
      a {
        background-color: $accent-warm-lighter;

        &:hover {
          background-color: $accent-warm;
        }
      }
    }
  }

  &.centered {
    text-align: center;

    a {
      padding: $size-20;
    }

    p {
      margin: $size-16 0 0;
      font-size: $size-20;
    }

    .icons {
      justify-content: center;

      svg {
        height: 46px;
        width: auto;
      }
    }

    &.hoverFill {
      svg {
        width: auto;
        height: $size-32;
      }

      a {
        &:hover {
          @media screen and (min-width: ($tablet-lg)) {
            background: $tertiary-lighter;
            border-color: $tertiary-vivid;
          }
        }
      }
    }
  }

  &.noLink {
    background: $primary-lighter;
    border-radius: $size-8;
    padding: $size-32 $size-16;
    text-align: center;
    color: $ink;

    &.blue {
      background-color: $primary-lighter;

      svg {
        color: $primary-dark;
      }
    }

    &.green {
      background-color: $secondary-lighter;

      svg {
        color: $secondary-dark;
      }
    }

    &.purple {
      background-color: $tertiary-lighter;

      svg {
        color: $tertiary-dark;
      }
    }

    &.navy {
      background-color: $info-lighter;

      svg {
        color: $info-dark;
      }
    }

    &.orange {
      background-color: $accent-warm-lighter;

      svg {
        color: $accent-warm-dark;
      }
    }

    .icons {
      justify-content: center;
    }

    .title {
      margin: $size-8 0;
      font-size: $size-22;
    }

    .description {
      display: block;
      overflow: visible;
    }

    svg {
      height: auto;
      width: 32px;
    }
  }
}
