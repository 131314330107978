.error-message {
  text-align: center;

  .inner {
    padding: 2rem;
    border: $size-1 solid $ink;
    border-radius: 15px;
  }

  svg {
    fill: $red;
  }

  .heading {
    font-weight: bold;
    font-size: 1.3rem;
  }
}
