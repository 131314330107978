.cta {
  text-align: center;
  padding: $size-32 $size-16;

  .heading-text {
    color: $ink;
    font-size: $size-32;
    font-weight: bold;
    margin: 0 auto $size-16;
    line-height: 1.2;
    max-width: $size-480;

    @media screen and (max-width: ($tablet-lg - 1)) {
      font-size: $size-20_8;
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $size-16;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 300px;

      &:hover {
        @media screen and (min-width: ($desktop)) {
          opacity: 0.8;
        }
      }
    }

    &.row {
      flex-direction: row;
      justify-content: center;
      gap: $size-16;

      @media screen and (max-width: ($mobile - 1)) {
        flex-direction: column;
      }

      a {
        width: auto;
        min-width: 140px;
      }
    }
  }

  &.color-blue {
    .links {
      a {
        color: $primary;
        box-shadow: inset 0 0 0 $size-2 $primary;
        background-color: $white;

        &:nth-of-type(1) {
          border: none;
          box-shadow: none;
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
  &.color-green {
    .links {
      a {
        color: $secondary;
        box-shadow: inset 0 0 0 $size-2 $secondary;
        background-color: $white;

        &:nth-of-type(1) {
          border: none;
          box-shadow: none;
          color: $white;
          background-color: $secondary;
        }
      }
    }
  }
  &.color-purple {
    .links {
      a {
        color: $tertiary;
        box-shadow: inset 0 0 0 $size-2 $tertiary;
        background-color: $white;

        &:nth-of-type(1) {
          border: none;
          box-shadow: none;
          color: $white;
          background-color: $tertiary;
        }
      }
    }
  }
  &.color-navy {
    .links {
      a {
        color: $accent-warm-dark;
        box-shadow: inset 0 0 0 $size-2 $info-darker;
        background-color: $white;

        &:nth-of-type(1) {
          background-color: $info-darker;
          border: none;
          box-shadow: none;
          color: $white;
        }
      }
    }
  }
  &.color-orange {
    .links {
      a {
        color: $accent-warm-dark;
        box-shadow: inset 0 0 0 $size-2 $accent-warm-dark;
        background-color: $white;

        &:nth-of-type(1) {
          background-color: $accent-warm-dark;
          border: none;
          box-shadow: none;
          color: $white;
        }
      }
    }
  }
}
