.update-notifier {
  margin: $size-48 0;

  .overlay {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0;
    transition-duration: $transition-speed;
    transition-timing-function: ease-in-out;

    &.open {
      opacity: 0.4;
      pointer-events: all;
    }
  }

  .usa-button {
    margin-right: 0;
    justify-content: center;
    margin-top: $size-16;

    &:disabled,
    &.submitting {
      background-color: $base;
      color: $white;
      min-height: $size-64;
      cursor: not-allowed;
    }
  }

  .update-content {
    background-color: $base-lightest;
    position: relative;
    border-radius: $size-24;
    padding: $size-48 0;

    &.fixed {
      z-index: 1000;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: $size-1400;
      position: fixed;
      transition-duration: $transition-speed;
      border-radius: $size-24 $size-24 0 0;
      transition-timing-function: ease-in-out;
      bottom: -$size-880;

      &.open {
        bottom: 0;
      }
    }

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $size-24;
      position: relative;
      z-index: 1;

      @media screen and (min-width: ($tablet-xl)) {
        padding-right: $size-48;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $size-20;
      padding: 0 $size-24;
      max-width: $size-880;

      @media screen and (min-width: ($tablet-xl)) {
        padding: 0 $size-48;
        width: calc(100% - $size-240);
      }

      .heading-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $size-20;

        &.status {
          gap: $size-12;
        }

        .status-message {
          margin-top: 0;
          text-align: left;
          font-size: $size-20;
          line-height: $size-28;
        }
      }

      .heading-tag {
        font-size: $size-22;
        margin: 0;
        text-align: center;
      }

      p {
        font-size: $size-16;
        text-align: center;

        &.copy {
          width: 100%;
          font-size: $size-14;
          text-align: left;
        }
      }

      a {
        color: $primary;

        &:hover {
          @media screen and (min-width: ($tablet-xl)) {
            color: $primary-dark;
          }
        }
      }

      form {
        text-align: center;
        width: 100%;
        max-width: none;
        margin: $size-8;

        .usa-form-group {
          margin: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: $size-24;
          align-items: center;

          .input-row {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: $size-32;

            @media screen and (min-width: ($tablet-xl)) {
              flex-direction: row;
            }
          }

          .input-wrapper {
            width: 100%;
            position: relative;
            padding: 0;

            &.error {
              input,
              .select-button {
                border-color: $error;
              }
            }
          }

          > * {
            margin-top: 0;
          }
        }
      }

      label {
        font-weight: bold;
        text-align: left;

        .require-mark {
          margin-left: $size-4;
        }

        + svg {
          position: absolute;
          top: $size-45;
          left: $size-12;
        }
      }

      input {
        height: $size-56;
        width: 100%;
        padding-left: $size-45;
        border-width: $size-2;
        border-radius: $size-4;
        border-color: $base;
      }
    }
  }

  .close-update-drawer {
    position: absolute;
    top: $size-24;
    right: $size-24;
    width: $size-32;
    height: $size-32;
    z-index: 1;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-message {
    color: $error;
    text-align: left;
    margin-top: $size-16;
    font-weight: bold;
  }

  .description-selector {
    display: block;
    font-weight: bold;
    position: relative;
    border-color: $base;

    &.open {
      &:after {
        border-left: 0.35rem solid transparent;
        border-right: 0.35rem solid transparent;
        border-bottom: 0.35rem solid $ink;
        border-top: 0;
      }
    }

    &:after {
      content: "";
      position: absolute;
      right: $size-16;
      bottom: $size-24;
      width: 0;
      height: 0;
      border-left: 0.35rem solid transparent;
      border-right: 0.35rem solid transparent;
      border-top: 0.35rem solid $ink;
    }

    select {
      margin-top: $size-12;
      width: 100%;
      appearance: none;
      padding: $size-12 $size-24 $size-12 $size-12;
    }
  }

  .select-button {
    margin-top: $size-8;
    width: 100%;
    color: $ink;
    background: $white;
    appearance: none;
    padding: $size-12 $size-32 $size-12 $size-12;
    position: relative;
    height: $size-56;
    font-weight: normal;
    text-align: left;
    width: 100%;
    border: $size-2 solid $base;
    border-radius: $size-4;

    &.greyed-out {
      color: $base;
    }
  }

  .dropdown-select {
    font-weight: normal;
    margin-top: $size-16;
    box-shadow: 0 10px 20px -15px $ink;
    position: absolute;
    background-color: $base-cool;
    z-index: 1;
    max-height: $size-300;
    overflow: auto;
    outline: 1px solid $base-lighter;
    border-radius: $size-8;
    width: 100%;

    &[aria-expanded="true"] {
      display: block;
    }

    button {
      display: block;
      width: 100%;
    }

    button {
      padding: $size-16;
      text-align: left;

      @media screen and (min-width: ($tablet)) {
        padding: $size-16;
      }

      &:nth-of-type(2n - 1) {
        background: $base-lighter;
      }

      &:hover {
        @media screen and (min-width: ($tablet)) {
          background: $purple;
        }
      }
    }
  }

  .require-mark {
    display: inline-block;
    color: $error;
  }
}
