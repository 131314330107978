.interrupter {
  background-color: $navy;
  color: white;
  padding: $size-32 0;

  .container {
    display: flex;
    align-items: center;
    gap: $size-16;

    &:before,
    &:after {
      content: none;
    }

    @media screen and (max-width: ($tablet-xl - 1)) {
      flex-direction: column;
    }
  }

  h4 {
    font-size: 1.7rem;

    @media screen and (min-width: ($tablet-xl)) {
      width: calc(100% - 300px);
    }

    @media screen and (max-width: ($tablet-xl - 1)) {
      text-align: center;
    }
    @media screen and (min-width: ($desktop)) {
      width: 40%;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: $size-16;

    @media screen and (max-width: ($tablet-xl - 1)) {
      flex-direction: column;
    }

    @media screen and (min-width: ($desktop)) {
      width: 60%;
    }
    > * {
      @media screen and (min-width: ($tablet-xl)) {
        width: 50%;
      }
    }
  }

  li {
    @media screen and (max-width: ($tablet-xl - 1)) {
      width: 100%;
    }
    .usa-button {
      text-align: left;
      background-color: $info-lighter;
      border: $size-2 solid $bright-blue;
      display: inline-flex;
      align-items: center;
      gap: $size-16;
      color: $navy;
      padding: $size-8 $size-16;
      border-radius: $size-10;

      @media screen and (max-width: ($tablet-xl - 1)) {
        display: flex;
        width: 100%;
      }

      &:hover {
        @media screen and (min-width: ($tablet-xl + 1)) {
          background: white;
        }
      }

      svg {
        width: $size-32;
        height: auto;
      }
    }
  }
}
